
import { defineComponent, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import PageStep from '@/components/layout/PageStep.vue'
import WizardStepBlockContainer from '@/components/pages/auth/wizard/WizardStepBlockContainer.vue'
import { wizardSteps } from '@/definitions/auth/data'

export default defineComponent({
  components: {
    PageStep,
    WizardStepBlockContainer,
  },
  props: {
    stepSize: {
      type: String,
    },
  },
  setup() {
    const currentIndex = ref(2)
    const route = useRoute()
    const country = computed(() => route.meta.country)

    const wizard = ref([
      {
        icon: 'tmi-phone-geo',
        title: 'Local virtual number',
        description: 'Local UK virtual numbers that support two-way messaging and voice features.',
        list: [
          '£2.40 per month',
          '<strong>Free registration</strong> of your use case',
          '<strong>Instant</strong> activation',
          '<strong>2-way SMS</strong> and voice calls supported',
          'Minimum initial credit required: £20',
        ],
        buttonText: 'Get started',
        link: { name: 'wizard.' + country.value + '.senderSettings' },
      },
      {
        icon: 'tmi-phone-globe',
        title: 'Alphanumeric Sender ID',
        description: 'Display your organization name (up 11 characters) as a sender of your text messages.',
        list: [
          '£10 per month',
          '<strong>Free registration</strong> of your use case',
          '<strong>Instant</strong> activation',
          '<strong>One-way messaging</strong> - no replies possible',
          'Minimum initial credit required: £20',
        ],
        buttonText: 'Get started',
        link: { name: 'wizard.' + country.value + '.senderId' },
      },
    ])

    return {
      wizardSteps,
      currentIndex,
      wizard,
    }
  },
})
